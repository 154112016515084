.react-epubjs .navigator {
    z-index: 2;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
}
.react-epubjs .navigator .more-info-area {
    width: 100%;
    height: 30%;
    opacity: 1;
    display: inline-block;
}
.react-epubjs .navigator .more-info-area i{
   margin-left: 10px;
   top: 0px;
   font-size: 28px;
   font-weight: bold;
   color: #aaa;
   opacity: 1;
   cursor: pointer;
   -webkit-transition: all 0.5s ease;
   -moz-transition: all 0.5s ease;
   -ms-transition: all 0.5s ease;
   -o-transition: all 0.5s ease;
   transition: all 0.5s ease;

}
.react-epubjs .navigator .more-info-area:hover i{
    color: #000;
    opacity: 1;
    transform: scale(2);
 }
.react-epubjs .navigator .next-area {
    width: 50%;
    height: 50%;

    float: right;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: end;
    right: 0px;
    cursor: pointer;
}
.react-epubjs .navigator .prev-area {
    width: 50%;
    height: 50%;

    float: left;
    position: absolute;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.react-epubjs .navigator .prev-area i, .react-epubjs .navigator .next-area i{
    font-size: 22px;
    margin: 5px;
    color: #aaa;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.react-epubjs .navigator .next-area:hover i{
    color: #000;
    opacity: 1;
    transform: scale(2) translateX(10px);    
}
.react-epubjs .navigator .prev-area:hover i{
    color: #000;
    opacity: 1;
    transform: scale(2) translateX(-10px);

}
.react-epubjs .navigator .page-number {
    position: absolute;
    width: 100%;
    background: #c0c0c0 2e;
    padding: 5px 15px;
    color: #888;
    text-align: center;
    bottom: 0;
}

.react-epubjs .navigator .bookmark-area{
    position: absolute;
    right: 10px;
    bottom: 10px;
}
.react-epubjs .navigator .bookmark-area i{
    font-size: 24px;
    cursor: pointer;
    color: #888;
    transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
.react-epubjs .navigator .bookmark-area i:hover{
    color: #000;
}
.react-epubjs .navigator .bookmark-area i:active{
    color: #ed1c24;
    font-size: 26px;
}