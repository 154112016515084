.searchBar input:checked {
    background-color: #000000;
    border-color: #0a0a0a;
}

@media (max-width: 576px) { 
.searchBar {
    position: absolute;
    z-index: 9;
    top: 55px;
    width: 100%;
    padding: 2%;
    margin: auto;
    left: 0;
}    
}
@media (min-width: 768px) and (max-width: 1023px) { 
    .searchBar {
        position: absolute;
        z-index: 9;
        top: 55px;
        width: 100%;
        padding: 2%;
        margin: auto;
        left: 0;
    }
}