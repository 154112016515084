*,
html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
a {
  text-decoration: none;
}
body {
  font-family: "Poppins", sans-serif;
  color: #000;
  font-size: 16px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.bottomPosition{bottom:inherit !important};

@media (max-width: 576px) {
  html,
  body {
    font-size: 12px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  body {
    font-size: 14px;
  }
 
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.textColorSecondary {
  color: #ed1c24;
}

.textColorPrimary {
  color: #333232;
}

.cursor-pointer {
  cursor: pointer;
}
@media (max-width: 576px) {
  .headerlogo img {
    width: 42vw;
    margin-left: 5px;
  }
  h2 {
    font-size: 1.2rem;
  }
}
@media (min-width: 577px) and (max-width: 1023px) {
  .headerlogo img {
    width: 30vw;
    margin-left: 15px;    
    margin-top: 15px;
  }
 
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .headerlogo img {
    width: 15vw;
    margin-left: 5px;
  }
 
}
.clearAll{
  clear: both;
}
.customNavlink {
  color: #393939;
  text-decoration: none;
  line-height: 1;
  padding-bottom: 5px;
  position: relative;
  cursor: pointer;
}

.customNavlink:hover,
.customNavlink.active {
  color: #ed1c24;
  text-decoration: none;
}
.customNavlink::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 1px;
  bottom: -3px;
  left: 0;
  background-color: #ed1c24;
  transition: clip-path 0.3s, transform 0.3s cubic-bezier(0.2, 1, 0.8, 1);
  clip-path: polygon(
    0% 0%,
    0% 100%,
    0 100%,
    0 0,
    100% 0,
    100% 100%,
    0 100%,
    0 100%,
    100% 100%,
    100% 0%
  );
}
.customNavlink:hover::before,
.customNavlink.active::before {
  transform: translate3d(0, 2px, 0) scale3d(1.08, 3, 1);
  clip-path: polygon(
    0% 0%,
    0% 100%,
    50% 100%,
    50% 0,
    50% 0,
    50% 100%,
    50% 100%,
    0 100%,
    100% 100%,
    100% 0%
  );
}

.custombtnlink {
  color: #393939;
  text-decoration: none;
  line-height: 1;
  cursor: pointer;
  -webkit-transition: background-color 500ms ease-out;
  -moz-transition: background-color 500ms ease-out;
  -o-transition: background-color 500ms ease-out;
  transition: background-color 500ms ease-out;
  -ms-transition: background-color 500ms ease-out;
}

.custombtnlink:hover {
  color: #ed1c24;
  text-decoration: none;
}

.footerLink {
  text-transform: uppercase;
  margin: 0px 15px;
}
.form-control:focus {
  box-shadow: none;
}

.readColor {
  color:#374ea2;
}
.writeColor {
  color:#39bb9d;
}
.expressColor {
  color:#bfd730;
}
.otherCoinColor {
  color:#faa620;
}
.redeemCoinColor {
  color:#ec0000;
}
.searchDrops{
  z-index: 2;
}
@media (max-width: 576px) {
  .footerLink {
    width: 40%;
    padding: 15px 0px;
    font-size: 12px;
    margin: 0px 10px;
  }
}
@media (max-width: 576px) {
  .searchDrops {
    margin: 5px 5px;
    padding: 0px;
  }
  .searchDropRow {
    margin: 5px 0px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .filterContainer {
    justify-content: center !important;
    padding: 20px 0px;
  }
  .searchDrops {
    width: 100%;
  }
}
#btnnotification {
  position: relative;
  margin: 0px 18px 0px 14px;
}
span.counttag {
  background: #ed1c24;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  position: absolute;
  top: 0px;
  right: -4px;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  color: #fff;
}

.userProfiledropblock span.userName {
  font-size: 14px;
}
.profileDropDown{
  display: flex;
  align-items: center;
}
.userProfileIcon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border: 2px solid #333;
  display: inline-flex;
  justify-content: center;
  margin-right: 5px;
}
.userProfileIcon:after {
  display: none;
}
.userProfileIcon img {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.filterContainer {
  min-height: 60px;
  background: #ed1c24;
  border-radius: 40px;
  color: #fff;
  flex-wrap: wrap;
}
@media (max-width: 576px) {
  .userProfileIcon {
    width: 25px;
    height: 25px;
  }
  .filterContainer {
    padding: 30px 10px;
    min-height: 80px;
    justify-content: center !important;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
}
.customCarousel .carousel-caption {
  position: relative;
  left: 0;
  color: #393939;
  text-align: left;
}

.customCarousel .carousel-caption H2 {
  margin-bottom: 30px;
}

.ratio-26x9 {
  --bs-aspect-ratio: calc(9 / 26 * 100%);
}
@media (max-width: 576px) {
  .ratio-26x9 {
    --bs-aspect-ratio: calc(9 / 12 * 100%);
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .ratio-26x9 {
    --bs-aspect-ratio: calc(9 / 21 * 100%);
  }
}

.bigFonts {
  text-align: center;
  margin-top: 50px;
  float: left;
}

.bigFonts h1 {
  font-size: 70px;
  line-height: 22px;
  font-weight: 900 !important;
}
.bigFonts h6 {
  margin-top: 20px;
  font-weight: 800 !important;
}
@media (max-width: 576px) {
  .bigFonts {
    margin-top: 20px;
    width: 33%;
  }
  .bigFonts h1 {
    font-size: 20px;
    font-weight: 800 !important;
  }
  .bigFonts h6 {
    margin-top: 10px;
    font-weight: 700 !important;
    font-size: 12px;
  }
}

@media (max-width: 576px) {
  .bigFonts h6 {
    font-size: 10px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .bigFonts h1 {
    font-size: 45px;
    font-weight: 800 !important;
  }
}

.registeredSchool {
  color: #374ea2;
}

.registeredStudents {
  color: #39bb9d;
}

.storiesbyStudents {
  color: #bfd730;
}

.paidStoriesCollections {
  color: #b62079;
}

.hopHighFooter {
  background: #030303;
  margin-top: 80px;
  position: relative;
}

.quote-Bg-Block {
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  min-height: 500px;
  display: flex;
  justify-content: center;
}
.quote-Bg-Block h2 {
  font-size: 36px;
  text-align: center;
  padding-top: 50px;
  height: fit-content;
  width: auto;
  margin-bottom: -4px;
}
.quote-Bg-Block span {
  font-size: 20px;
  display: block;
  font-style: italic;
  color: #888;
  text-align: right;
  padding-top: 5px;
  padding-right: 18px;
}
@media (max-width: 576px) {
  .quote-Bg-Block {
    background-size: contain;
    min-height: 250px;
    background-color: #f5f5f5;
  }
  .quote-Bg-Block h2 {
    font-size: 18px;
    text-align: center;
    padding-top: 20px;
  }
  .quote-Bg-Block span{
    font-size: 16px;
  }
  .hopHighFooter {
    font-size: 12px;
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  .quote-Bg-Block {
    background-size: contain;
    min-height:400px;
    background-color: #f5f5f5;
  }
  .quote-Bg-Block h2{
    padding-top: 20px;
  }
}

.card .card-body {
  padding-bottom: 0px;
  cursor: pointer;
  border-radius: 0.25rem 0.25rem 0 0;
  -webkit-border-radius: 0.25rem 0.25rem 0 0;
  -moz-border-radius: 0.25rem 0.25rem 0 0;
  -ms-border-radius: 0.25rem 0.25rem 0 0;
  -o-border-radius: 0.25rem 0.25rem 0 0;
}
.card.videocard .card-body {
  border-radius: 0rem;
  -webkit-border-radius: 0rem;
  -moz-border-radius: 0rem;
  -ms-border-radius: 0rem;
  -o-border-radius: 0rem;
}
.card .card-body h2 {
  min-height: 75px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
}

.card .card-text {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 100px;
  height: 100px;
}
.card .smcontent a{
  color: #000;
  font-size: 14px;
  text-decoration: underline;
}

.card .smcontent a:hover{
  font-weight: bold;
}

.genreBox{
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-height: 25px;
  height: 38px;

}


@media (max-width: 576px) {
  /* .card{
    -webkit-box-shadow: 0px 0px 9px -2px rgba(0,0,0,0.57);
-moz-box-shadow: 0px 0px 9px -2px rgba(0,0,0,0.57);
box-shadow: 0px 0px 9px -2px rgba(0,0,0,0.57);
  } */
  .card .card-body{
    padding: 4px 6px !important;
  }
  .card .card-body h2 {
    min-height: 35px;
    font-size: 14px;
    margin-bottom: 8px;
  }

  .card .card-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 60px;
    display: none;
  }
  .card .smlabel{
    font-size: 10px;
    line-height: 20px;
  }
  .card .smcontent{
    font-size: 9px;
    line-height: 10px;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 10px;
  }
  .card .smgenrecontent{
    font-size: 9px;
    line-height: 12px;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 13px;
  }

  .card.videocard .card-body h2 {
    display: none;
  }

  .card .smcontent a{
    font-size: 10px;
  }

}
@media (min-width: 1024px) and  (max-width: 1200px) {
  .card .smcontent{
 
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 10px;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .card .card-body h2 {
    min-height: 50px;
    font-size: 20px;
    margin-bottom: 8px;
  }
}


section {
  padding: 30px 0px;
}
@media (max-width: 576px) {
  section {
    padding: 15px 0px;
  }
}
.btnGeneral {
  padding: 0.475rem 1.8rem;
  text-transform: uppercase;
}
#hophigh_herobanner .carousel-item {
  background: #fff;
}

#hophigh_herobanner .carousel-caption {
  text-align: left;
}
#hophigh_herobanner .carousel-caption h2 {
  font-weight: 600;
  font-size: 38px;
  line-height: 3.3rem;
  color: #000;
}
@media (max-width: 1023px) {
  #hophigh_herobanner h2 {
    font-size: 26px;
  }
  #hophigh_herobanner .carousel-item .img img {
    max-height: 100% !important;
  }
  #hophigh_herobanner .carousel-caption h2 {
    font-size: 30px;
    line-height: 2.6rem;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  #hophigh_herobanner .carousel-caption h2 {
    font-size: 32px;
    line-height: 2.8rem;
  }
  #hophigh_herobanner .carousel-item .img img {
    max-height: 100% !important;
  }
}
#hophigh_herobanner .carousel-indicators {
  margin-bottom: -25px;
}
#hophigh_herobanner .carousel-indicators button {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border: 8px solid #333;
  height: 4px;
  width: 4px;
  background: #333;
  margin: 0px 3px;
  cursor: pointer;
}
#hophigh_herobanner .carousel-indicators button.active {
  background: #fff;
}
@media (max-width: 576px) {
  #hophigh_herobanner .carousel-caption h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 1.6rem;
    padding-left: 20px;
    color: #fff;
    text-shadow: 2px 2px 2px #000000;
  }
  #hophigh_herobanner .carousel-item .img img {
    position: relative;
  }
  #hophigh_herobanner .carousel-item .img img:after {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    display: table;
    max-width: 100%;
    height: 100%;
    background: black;
    border-radius: 8px;
    background: linear-gradient(
      60deg,
      rgba(0, 0, 0, 0.44) 30%,
      rgba(255, 255, 255, 0) 100%
    );
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
  }
}

.open {
  width: 35px;
  height: 26px;
  display: flex;
  align-items: stretch;
  cursor: pointer;
  transition: opacity 0.2s linear;
  position: relative;
}
.open .bi-list {
  font-size: 34px;
}
.open:hover {
  opacity: 0.8;
}
.open span {
  display: block;
  float: left;
  clear: both;
  height: 3px;
  width: 22px;
  border-radius: 40px;
  background-color: #000;
  position: absolute;
  right: 6px;
  z-index: 999;
  overflow: hidden;
  transition: all 0.4s ease;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
}
.open span:nth-child(1) {
  margin-top: 3px;
  z-index: 999;
}
.open span:nth-child(2) {
  margin-top: 12px;
}
.open span:nth-child(3) {
  margin-top: 21px;
}

.sub-menu {
  height: 0;
  width: 0;
  left: 0;
  top: 0;
  position: absolute;
  background-color:#fff;
  border: 1px solid #999;
  border-radius: 20px;
  z-index: 998;
  overflow: hidden;
  transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -moz-transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -ms-transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -o-transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
}
.sub-menu ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
  margin: 0;
  padding: 0;
  width: 100%;
}
.sub-menu li {
  display: block;
  float: left;
  clear: both;
  height: auto;
  margin-left: 2%;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  width: 45%;
  -webkit-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -moz-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -ms-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -o-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.sub-menu li:nth-child(1) {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}
.sub-menu li:nth-child(2) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.sub-menu li:nth-child(3) {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}
.sub-menu li:nth-child(4) {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.sub-menu li:nth-child(5) {
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
}
.sub-menu li a {
  color: #000 !important;
  font-size: 18px;
  width: 100%;
  display: block;
  float: left;
  padding: 0.5rem 0.5rem;
}
.oppenned .sub-menu {
  opacity: 1;
  height: 500px;
  width: 100%;
  z-index: 998;
  margin: 0;
  padding: 0;
  margin-top: 60px;
}
@media (max-width: 576px) {
  .open .bi-list {
    font-size: 27px;
  }
  .sub-menu li {
    width: 100%;
    margin-left: 0px;
  }
  .sub-menu li a {
    opacity: 0;
    padding: 0.6rem 0.5rem;
    color: #000 !important;
    font-weight: 700;
    background: rgba(255, 255, 255, 0.459);
    margin: 6px;
    font-size: 16px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    transition: all 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    -webkit-transition: all 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    -moz-transition: all 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    -ms-transition: all 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    -o-transition: all 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  .sub-menu li a:hover, .sub-menu li a.active {
    background: #000;
    color: #fff !important;
  }
  .mobMenuClose {
    display: inline-block;
    position: absolute;
    right: 5px;
    top: 10px;
    width: 50px;
    height: 50px;
    text-align: center;
  }
  .mobMenuClose i {
    font-size: 32px;
    color: #000;
  }
  .mobMenuClose i::before{
    color: #000;
    font-size: 20px;
  }
  .oppenned .sub-menu {
    display: flex;
    justify-content: space-evenly;
  }
  .oppenned .sub-menu ul {
    margin-bottom: 20px;
  }
}

/* .oppenned span:nth-child(2) {
  overflow: visible;
}
.oppenned span:nth-child(1), .oppenned span:nth-child(3) {
  z-index: 999;
  transform: rotate(45deg);
}
.oppenned span:nth-child(1) {
  transform: rotate(45deg) translateY(1px) translateX(2px);
  -webkit-transform: rotate(45deg) translateY(1px) translateX(2px);
  -moz-transform: rotate(45deg) translateY(1px) translateX(2px);
  -ms-transform: rotate(45deg) translateY(1px) translateX(2px);
  -o-transform: rotate(45deg) translateY(1px) translateX(2px);
  z-index: 999;
}
.oppenned span:nth-child(2) {
  height: 450px;
  width: 450px;
  right: -160px;
  top: -160px;
  border-radius: 50%;
  background-color: rgba(237, 28, 36, 0.54);
  z-index: 998;
}
.oppenned span:nth-child(3) {
  transform: rotate(-45deg) translateY(-10px) translateX(10px);
} */
.oppenned span {
  width: 25px;
}
.oppenned .sub-menu li a {
  opacity: 1;
}
.oppenned .sub-menu li a:hover{
  background-color: #000;
  color: #fff;
}
.sub-menu .custButton.secondary {
  
  border-width: 2px;
  font-size: 15px;
  letter-spacing: 0.2rem;
  padding: 0.4rem 1.5rem;
}
.sub-menu .customNavlink {
  font-size: 15px;
  color: #000;
  letter-spacing: 0.2rem;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .oppenned .sub-menu {
    display: flex;
    justify-content: space-between;
    max-height: 350px;
  }
  .oppenned .sub-menu > div {
    order: 2;
    background: #0505054f;
    border-radius: 0px 0px 20px 20px;
    -webkit-border-radius: 0px 0px 20px 20px;
    -moz-border-radius: 0px 0px 20px 20px;
    -ms-border-radius: 0px 0px 20px 20px;
    -o-border-radius: 0px 0px 20px 20px;
  }
  .oppenned .sub-menu ul {
    padding: 1.5rem;
    margin-top: 1.8rem;
  }
  .mobMenuClose {
    display: inline-block;
    position: absolute;
    right: 5px;
    top: 10px;
    width: 50px;
    height: 50px;
    text-align: center;
  }
  .mobMenuClose i {
    font-size: 32px;
    color: #000;
  }
}
.cardSocials {
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;
  background: #eee;
  border-radius: 0 0 0.25rem 0.25rem;
  -webkit-border-radius: 0 0 0.25rem 0.25rem;
  -moz-border-radius: 0 0 0.25rem 0.25rem;
  -ms-border-radius: 0 0 0.25rem 0.25rem;
  -o-border-radius: 0 0 0.25rem 0.25rem;
}

.cardSocials a {
  display: flex;
  align-content: center;
  cursor: pointer;
}
.cardSocials a i {
  font-size: 18px;
}
.cardSocials > span:hover a i {
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -moz-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -ms-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -o-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.cardSocials a span.count {
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-left: 4px;
}
.cardSocials > span {
  padding: 3px 6px;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -moz-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -ms-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -o-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.cardSocials > span:hover {
  background: #fff;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.cardSocials a i.bi-heart-fill,
.cardSocials a i.bi-bookmark-fill,
.cardSocials a i.bi-person-plus-fill,
.bi-funnel-fill
{
  color: #ed1c24;
}

.innerBannerWrapper {
  height: 0;
  overflow: hidden;
  padding-top: calc((9 / 26) * 100%);
  background: white;
  position: relative;
}
.innerBannerWrapper .innerBanner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0px 30px;
}
.flexbox-centering {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: center;
}
@media (max-width: 576px) {
  .innerBannerWrapper {
    height: 0;
    overflow: hidden;
    padding-top: calc((9 / 16) * 100%);
    background: white;
    position: relative;
  }
  .innerBannerWrapper .innerBanner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0px 0px;
  }
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  border-radius: 0.25rem 0.25rem 0px 0px;
  -webkit-border-radius: 0.25rem 0.25rem 0px 0px;
  -moz-border-radius: 0.25rem 0.25rem 0px 0px;
  -ms-border-radius: 0.25rem 0.25rem 0px 0px;
  -o-border-radius: 0.25rem 0.25rem 0px 0px;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.btn-outline-dark.rounded-pill {
  font-weight: bold;
  font-size: 14px;
}

.selectBorder0 .react-select__control {
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
}

.react-select__control {
  border: 1px solid #ed1c24 !important;
  border-radius: 30px !important;
  -webkit-border-radius: 30px !important;
  -moz-border-radius: 30px !important;
  -ms-border-radius: 30px !important;
  -o-border-radius: 30px !important;
  box-shadow: none !important;
}
.react-select__dropdown-indicator {
  color: #000 !important;
}
.react-select__indicator-separator {
  background: transparent !important;
}
.react-select__menu{
  z-index: 99999 !important;
}
.react-select__menu-list {
  color: #000;
}
.react-select-container .react-select__option--is-focused,
.react-select-container .react-select__option--is-focused:active,
.react-select-container .react-select__option:active {
  background: #000;
  color: #fff;
}
.react-select-container .react-select__option--is-selected,
.react-select-container .react-select__option--is-selected:active {
  background-color: #000;
  color: #fff;
  font-weight: bold;
}
/* .react-select-container {
  z-index: 8;
} */

.react-sel-form .react-sel-form__option--is-focused,
.react-sel-form .react-sel-form__option--is-focused:active,
.react-sel-form .react-sel-form__option:active {
  background: #000;
  color: #fff;
}
.react-sel-form .react-sel-form__option--is-selected,
.react-sel-form .react-sel-form__option--is-selected:active {
  background-color: #000;
  color: #fff;
  font-weight: bold;
}
.react-sel-form .react-sel-form__menu{
  z-index: 3;
}


@media (min-width: 1024px) and (max-width: 1200px) {
  .react-select-container{
    font-size: 13px;
  }
  .react-select__menu{
    font-size: 13px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 950px !important;
  }
}

.custButton {
  display: inline-block;
  padding: 0.45rem 1.8rem;
  border: 0px solid #000;
  border-radius: 20px;
  color: #fff;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.1rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  text-decoration: none;
  z-index: 1;
  width: auto;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
.custButton:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  border-radius: 20px;
  z-index: -2;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
.custButton:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #ed1c24;
  border-radius: 20px;
  z-index: -1;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
.custButton:hover {
  color: #fff;
}
.custButton:hover:before {
  width: 100%;
}
.custButton.lg {
  padding: 0.65rem 1.8rem;
}
.custButton.md {
  padding: 0.55rem 1.8rem;
}


.custButton.inverse:before {
  background-color: #e9d200;
}
.custButton.inverse:hover {
  color: #000;
}
.custButton.secondary {
  color: #000;
  border: 1px solid #000;
  padding: 0.25rem 1.2rem;
  box-shadow: none;
}
.custButton.secondary:after {
  background-color: transparent;
}
.custButton.secondary:hover {
  color: #fff;
}
.custButton.hollow {
  color: #000;
  border: 1px solid #000;
  background: #fff;
  box-shadow: none;
}
.custButton.hollow:after {
  background-color: transparent;
}
.custButton:disabled:hover {
  background: #efefef;
}
.custButton.hollow:hover {
  color: #fff;
}
.custButton:disabled {
  box-shadow: none;
  color: #c7c7c7;
  cursor:not-allowed;
}
.custButton:disabled::after {
  background-color: #e0dede;
}
.custButton:disabled:hover::before {
  width: 0%;
}
.custButton:disabled:hover {
  color: #c7c7c7;
}
@media (max-width: 576px) {
  .custButton {
    padding: 0.45rem 1.2rem;
  }
  .custButton.mobileSize{
    font-size: 12px;
    padding: 0.20rem 1.0rem;
    position: absolute;
    bottom: 5px;
    right: 0px;
  }
}
@media (max-width: 576px) {
  .custButton {
    font-size: 11px;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .custButton {
    padding: 0.45rem 1.2rem;
    font-size: 10px;
  }
}

.shadowEffect {
  position: relative;
  background: #fff;
  border: 1px solid transparent;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  transition: all 0.35s ease-out;
  -webkit-transition: all 0.35s ease-out;
  -moz-transition: all 0.35s ease-out;
  -ms-transition: all 0.35s ease-out;
  -o-transition: all 0.35s ease-out;
}
.shadowEffect:hover {
  border: 1px solid #ddd;
  z-index: 999;
  box-shadow: 0px 2px 18px 0px rgb(0 0 0 / 30%);
  transform: scale(1.02);
  -webkit-transform: scale(1.02);
  -moz-transform: scale(1.02);
  -ms-transform: scale(1.02);
  -o-transform: scale(1.02);
}

.shadowEffect2 {
  position: relative;
  background: #fff;

  transition: all 0.35s ease-out;
  -webkit-transition: all 0.35s ease-out;
  -moz-transition: all 0.35s ease-out;
  -ms-transition: all 0.35s ease-out;
  -o-transition: all 0.35s ease-out;
}
.shadowEffect2:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}

.swatches-picker > div {
  width: 100%;
  overflow: hidden !important;
}

.ql-editor {
  overflow-y: none;
  resize: vertical;
  min-height: 18em;
}

.top-banner-section {
  display: grid;
  grid-template-columns: 1.3fr 2fr;
  grid-template-rows: 480px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  align-content: center;
  justify-content: center;
}
@media (min-width: 768px) and (max-width: 1200px) {
  .top-banner-section {
    grid-template-rows: auto;
  }
}
.top-banner-section .banner-image-div {
  grid-area: 1/2/1/2;
}
.top-banner-section .banner-overlay-div {
  grid-area: 1/1/2/3;
}
.top-banner-section .banner-text-div {
  grid-area: 1/1/1/3;
}

.banner-image {
  display: grid;
  min-width: 350px;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.banner-text-div {
  display: grid;
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;
}
.banner-overlay-div {
  display: none;
}
.banner-h1-text {
  font-weight: bold;
  width: 40%;
  margin-bottom: 30px;
}

@media (max-width: 576px) {
  .banner-h1-text {
    width: 80%;
    font-size: 22px;
    color: #fff;
  }
  .banner-text-div {
    align-items: end;
  }
  .banner-overlay-div {
    display: grid;
    max-width: 100%;
    background: black;
    border-radius: 8px;
    background: linear-gradient(
      60deg,
      rgba(0, 0, 0, 0.34) 30%,
      rgba(255, 255, 255, 0) 100%
    );
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
  }

  .top-banner-section {
    grid-template-rows: 280px;
  }
}
@media (max-width: 349px) {
  .banner-h1-text {
    width: 80%;
    font-size: 17px;
    color: #fff;
    text-shadow: 2px 1px 1px rgba(0,0,0,0.68);
    line-height: 1.2rem;
  }
  .banner-image {
    display: grid;
    min-width: 270px;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .top-banner-section {
    grid-template-rows: 180px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .banner-h1-text {
    width: 45%;
  }
}

.banner-body-text {
  font-size: calc(10pt + 0.15vw);
  margin-top: 0.5em;
  text-decoration: none;
}

hr {
  margin: 1.875em 0;
  border: none;
  border-bottom: solid 1px #67743a;
}
.dashed {
  border: none;
  height: 1px;
  background: #ff2c2c;
  background: repeating-linear-gradient(
    90deg,
    #67743a,
    #67743a 6px,
    transparent 6px,
    transparent 12px
  );
}
.customShare {
  position: relative;
  padding-top: 3px;
  display: block;
  cursor: pointer;
}

.customShare div {
  background-color: transparent !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}
.customShare div .st-label {
  display: none !important;
}
.customShare div.st-btn {
  min-width: 24px !important;
  padding: 0px !important;
  overflow: hidden;
}
.customShare div.st-btn > img {
  top: 50px !important;
}
.icon-search {
  margin-right: 4px;
}
.icon-search a i {
  font-size: 28px;
}
.icon-user {
  margin-right: 4px;
}
.icon-user a i {
  font-size: 28px;
}
.bi-bell{
  font-size: 30px;
}

@media (max-width: 576px) {
  .icon-search a i {
    font-size: 20px;
  }
  .bi-bell{
    font-size: 25px;
  }
}

[class^="hvr-"] {
  margin: 0.4em;
  padding: 0.4em 1.5em;
  cursor: pointer;
  background: #000;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.2em;
  border-radius: 20px;
  overflow: hidden;
  color: #fff;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
@media (max-width: 349px) {
  [class^="hvr-"] {
    font-size: 12px;
  }
}
.hvr-bounce-to-right {
  text-transform: uppercase;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgb(0 0 0 / 0%);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-bounce-to-right:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ed1c24;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-bounce-to-right:hover,
.hvr-bounce-to-right:focus,
.hvr-bounce-to-right:active {
  color: white;
}
.hvr-bounce-to-right:hover:before,
.hvr-bounce-to-right:focus:before,
.hvr-bounce-to-right:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}
.hvr-bounce-to-right:hover,
.hvr-bounce-to-right:focus,
.hvr-bounce-to-right:active {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  -moz-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  -o-transform: translateY(-5px);
}
.customForm .form-label {
  margin-top: 15px;
}
.customforminput {
  border: 0 0 0 !important;
  border-right: 0 !important;
  border-left: 0 !important;
  border-top: 0 !important;
  border-bottom: 1px solid#707070 !important;
  border-radius: 0 !important;
  position: relative;
}

.customForm input:checked {
  background-color: #000;
  border-color: #000;
}

.ytp-large-play-button-bg {
  fill: #1f1f1f;
  fill-opacity: 0.81;
  transition: fill 0.1s cubic-bezier(0.4, 0, 1, 1) 0s,
    fill-opacity 0.1s cubic-bezier(0.4, 0, 1, 1) 0s;
}
.m_overlay:hover .ytp-large-play-button-bg {
  fill: #cc181e;
  fill-opacity: 1;
  transition: fill 0.1s cubic-bezier(0, 0, 0.2, 1) 0s,
    fill-opacity 0.1s cubic-bezier(0, 0, 0.2, 1) 0s;
}
.m_yt_button {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border-radius: 10px;
  height: 30px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 42px;
  z-index: 5;
  display: block;
}
@media (max-width: 576px) {
  .m_yt_button {
    width: 24px;
    height: 28px;
  }
}
.m_yt_title {
  color: #fff;
  outline: 0 none;
  text-decoration: none;
  transition: color 0.1s cubic-bezier(0, 0, 0.2, 1) 0s;
  float: left;
  max-width: 100%;
  overflow: hidden;
  overflow-wrap: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 18px;
  padding: 14px 16px 0;
}

.backTransparent {
  background: transparent !important;
}
.cardInfoBox {
  display: flex;
  align-items: center;
  padding: 15px 0px 10px;
  clear: both;
}
.cardInfoBox .cardSocials {
  padding: 0px;
}
.cardInfoBox .cardSocials > span {
  padding: 3px 15px;
}
@media (max-width: 576px) {
  .cardInfoBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
    padding: 15px 0px 10px;
    position: relative;
  }
  .cardInfoBox .cardSocials > span {
    padding: 3px 8px;
  }
}

.hophighModal .modal-header {
  border-bottom: 0px;
}
.hophighModal .closebtn {
  position: absolute;
  right: 25px;
  top: 25px;
  cursor: pointer;
}

.hophighModal .modal-content {
  border-radius: 40px;
  padding: 25px;
}

.purpleModal .modal-content {
  background: #b62079;
}

@media (max-width: 576px) {
  .hophighModal .modal-content {
    padding: 10px;
  }
}
.reportIcon{
  position: relative;
  cursor: pointer;
}
.speechIconsBar a i,
.reportIcon a i {
  font-size: 24px;
  line-height: 24px;
}
.reportIcon a span {
  margin-left: 10px;
  font-weight: bold;
}
.speechIconsBar {
  display: flex;
  align-self: flex-end;
  cursor: pointer;
}
.speechIconsBar a {
  display: inline-flex;
  border: 2px solid #ccc;
  margin: 0px 4px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}
@media (max-width: 576px) {
  .speechIconsBar {
    position: absolute;
  }
  .reportIcon {
    position: absolute;
    right: 0;
    top: 20px;
  }
  .reportIcon a span {
   font-size: 12px;
  }
  .reportIcon a i {
    font-size: 20px;
    line-height: 20px;
  }
}
.purpleColor {
  color: #b62079;
}

@media (max-width: 576px) {
  .smartbox {
    margin: 20px 0px;
    border: 1px solid #eee;
    float: none !important;
    padding: 10px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
  }
  h3.purpleColor{
    font-size: 16px;
  }
}

.moveTop {
  position: absolute;
  background: #ed1c24;
  width: 80px;
  height: 100%;
  right: 15%;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.moveTop i {
  font-size: 42px;
  color: #fff;
}
@media (max-width: 576px) {
  .moveTop {
    position: absolute;
    background: #ed1c24;
    width: 40px;
    height: 40px;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .moveTop i {
    font-size: 32px;
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  .moveTop {
    right: 4%;
  }
}
.txtArea {
  resize: none;
  outline: none;
  width: 100%;
  height: 70vh;
  padding: 20px;
  font-size: large;
}
.txtArea {
  resize: none;
  outline: none;
  width: 100%;
  height: 30vh;
  padding: 20px;
  font-size: large;
}
.diary-box {
  border: 1px solid #ccc;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  width: 100%;
  min-height: 150px;
  background: #f1f5f8;
  position: relative;
  /* filter: blur(2px); */
}
.unlock-box {
  position: absolute;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 98%;
  height: 90%;
  background: #efefef;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
.diaryView {
  padding: 20px;
}
.diaryView > div {
  display: flex;
  justify-content: flex-start;
  margin: 10px;
}
.diaryView span {
  display: inline-block;
  /*display: flex;*/
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 60px;
  padding: 3px 15px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  background-color: #fff;
  text-align: center;
}
.diaryView p {
  display: inline-block;
  background-color: #fff;
  width: 90%;
  padding: 4px 15px;
  margin-left: 10px;
  min-height: 80px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
.diaryView p > br {
  margin-bottom: 10px;
}
@media (max-width: 576px) {
  .diaryView {
    padding: 2px;
  }
  .diaryView > div {
    display: flex;
    flex-direction: column;
    margin: 10px;
  }
  .diaryView span {
    width: 100%;
    display: flex;
    height: 40px;
  }
  .diaryView p {
    width: 100%;
    padding: 4px 5px;
    margin-left: 0px;
    margin-top: 3px;
  }
  .diaryView span h3 {
    margin-right: 5px;
  }
}

.form-control {
  position: relative;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  width: 100%;
  height: 40px;
  border: none;
  padding: 0 10px;
  box-shadow: none;
  outline: none;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.form-control.border-bottom {
  position: relative;
  background: transparent;
  padding: 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
}
.form-control.border-bottom ~ .border-bottom-animation {
  position: absolute;
  content: "";
  width: 0;
  background: rgba(0, 0, 0, 0.2);
  height: 2px;
  z-index: 99;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
/* Border bottom center */
.form-control.border-bottom ~ .border-bottom-animation.both-side {
  height: 0px;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
}
.form-control.border-bottom ~ .border-bottom-animation.both-side:before,
.form-control.border-bottom ~ .border-bottom-animation.both-side:after {
  position: absolute;
  content: " ";
  bottom: 0;
  width: 0;
  height: 2px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.form-control.border-bottom ~ .border-bottom-animation.both-side:before {
  left: 0;
}
.form-control.border-bottom ~ .border-bottom-animation.both-side:after {
  right: 0;
}
.form-control.border-bottom:focus ~ .border-bottom-animation.both-side:before,
.form-control.border-bottom:focus ~ .border-bottom-animation.both-side:after {
  background: #4285f4;
  width: 50%;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.8rem) translateX(-0.8rem);
  -webkit-transform: scale(0.85) translateY(-0.8rem) translateX(-0.8rem);
  -moz-transform: scale(0.85) translateY(-0.8rem) translateX(-0.8rem);
  -ms-transform: scale(0.85) translateY(-0.8rem) translateX(-0.8rem);
  -o-transform: scale(0.85) translateY(-0.8rem) translateX(-0.8rem);
}
.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.2rem;
}

.customAccordion .accordion-item {
  border: 0px;
}

.customAccordion .accordion-button {
  background: none;
}

.customAccordion .accordion-button:not(.collapsed) {
  box-shadow: none;
}

.customAccordion .accordion-item {
  border-bottom: 1px dashed #ccc;
}

.customAccordion .accordion-item:last-child {
  border-bottom: 0px;
  margin-bottom: 20px;
  padding-bottom: 25px;
}

.customAccordion .accordion-button:focus {
  border-color: transparent;
  box-shadow: none;
}
@media (max-width: 576px) {
.innerContent{
  font-size: 12px;
}
}
/* .innerContent{
  border-left: 6px solid #ed1c24;
  padding: 20px;
  background-color: rgb(255,255,255);
  background: linear-gradient(275deg, rgba(255,255,255,1) 0%, rgba(237,229,229,0.8774860285911239) 100%);
}
.innerContent p:nth-child(1){
  font-size: 22px;
  color: #000;
}
.innerContent p:nth-child(2){
  font-size: 16px;
  color: #666;
} */

.searchShow {
  display: block !important;
}
.dropdown-item {
  font-size: 14px;
}
.dropdown-item:hover {
  background-color: #ed1c24;
  color: #fff;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #ed1c24;
}

.customloader-home svg {
  width: 100%;
}
.maskContent {
  mask-image: linear-gradient(#fff, #fff, rgba(255, 255, 255, 0));
  -webkit-mask-image: linear-gradient(#fff, #fff, rgba(255, 255, 255, 0));
  overflow: hidden;
  position: relative;
  max-height: 280px;
}

.disabled {
  color: #ccc;
  cursor: not-allowed;
}

.disabled:hover {
  color: #ccc;
  cursor: not-allowed;
}

.toast {
  width: auto;
  background-color: #ed1c24;
  color: #fff;
  max-width: 60vw;
}
.toast-header {
  color: #000;
}
.toast-header span {
  padding: 0px 8px;
}
.toast-header span i {
  color: #ed1c24;
}
.toast-header .me-auto {
  padding-right: 20px;
}
.toast-body h6{
  font-size: 0.85rem;
}
.toast-body h6 span{
  font-weight: bold;
}

#drpNotification.dropdown-toggle:after {
  display: none;
}

.userProfiledropblock .dropdown-toggle::after {
  display: none;
}
.userProfiledropblock .dropdown-toggle span {
  color: #000;
  font-size: 14px;
  cursor: pointer;
}
.notificationblock .dropdown-item {
  font-size: 12px;
  border-bottom: 1px solid #eee;
  color: #666;
}
.notificationblock .dropdown-item:hover {
  color: #fff;
}
.notificationblock .dropdown-item span {
  display: block;
  font-size: 9px;
}
.notificationblock .dropdown-item.btnSeeAll {
  text-align: center;
  border-bottom: 0px solid;
  font-weight: 700;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: transparent;
  color: #999;
  opacity: 1;
}

.myPlanner {
  padding: 2rem 1rem;
}

.fc-col-header-cell a {
  color: #d42f82;
}
.fc-daygrid-day-number {
  color: #000;
}
.fc-scrollgrid {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.fc .fc-scrollgrid-section-liquid > td {
  border-radius: 0px 0px 10px 10px;
  -webkit-border-radius: 0px 0px 10px 10px;
  -moz-border-radius: 0px 0px 10px 10px;
  -ms-border-radius: 0px 0px 10px 10px;
  -o-border-radius: 0px 0px 10px 10px;
}
.fc .fc-highlight {
  background-color: #ed1c2325;
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: rgb(255, 230, 183);
}
.fc .fc-button-primary {
  background-color: #d42f82;
  border-color: #fff;
}
.fc .fc-button-primary:disabled {
  background-color: #979797;
}
.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
  background-color: #860d67;
  border-color: #fff;
}
.fc .fc-button-primary:hover {
  background-color: #860d67;
  border-color: #fff;
}
.fc .fc-daygrid-event {
  border-color: #333;
}
.fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
  margin-left: 0.3em;
}

@media (max-width: 576px) {
  .fc-toolbar-chunk:nth-child(2) {
    position: absolute;
    top: 35px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
  }
  .fc .fc-view-harness {
    margin-top: 30px;
  }
  .fc .fc-button{
    font-size: 0.75em;
    padding: 0.4em 0.5em;
  }
  .fc .fc-toolbar-title {
    font-size: 1.15em;
  }
  .fc .fc-toolbar.fc-header-toolbar{
    margin-bottom: 0.5em;
  }

}

.nocard {
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  border: 1px solid #f0f0f0;
  background: #f9f9f9;
  padding: 20px 10px;
  text-align: center;
  min-height: 280px;
  display: flex;
  flex-flow: column;
  justify-content: center;
}
.nocard h2 {
  color: #ccc;
  text-transform: capitalize;
}
.nocard a {
  display: inline-block;
  margin: 5px 20px;
  font-size: 28px;
  color: #ed1c24 !important;
  text-decoration: underline !important;
}

.birthdayLabel {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.8rem) translateX(-0.8rem);
  -webkit-transform: scale(0.85) translateY(-0.8rem) translateX(-0.8rem);
  -moz-transform: scale(0.85) translateY(-0.8rem) translateX(-0.8rem);
  -ms-transform: scale(0.85) translateY(-0.8rem) translateX(-0.8rem);
  -o-transform: scale(0.85) translateY(-0.8rem) translateX(-0.8rem);
  padding: 1rem 0.75rem;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #ed1c24;
}
.react-datepicker__header {
  border-bottom: 1px solid #ed1c24;
}

.react-datepicker__day--selected:hover {
  background-color: #ed1c24;
}
.datagrid table {
  width: 100%;
}

.datagrid table th {
  color: #fff;
  background: #b60a68;
  padding: 12px 5px;
}
.datagrid table tr:nth-child(even) {
  background: #f3d4e5;
}
.datagrid table tr:nth-child(odd) {
  background: #fff;
}
.datagrid table td {
  padding: 6px;
  font-size: 13px;
  font-weight: bold;
}
.datagrid table td a {
  color: #000;
  text-decoration: underline;
}
.datagrid table td a:hover {
  color: #b60a68;
}

.pagination {
  padding: 10px 0px;
  background: #b60a68;
  color: #fff;
  display: flex;
  justify-content: end;
}
.pagination a {
  display: inline-block;
  margin: 0px 10px;
  width: 30px;
  height: 30px;
  border: 2px solid #fff;
  color: #fff;
  text-align: center;
  font-size: 18px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.pagination span {
  line-height: 30px;
}
.profileImage {
  width: 100%;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #999;
}
@media (max-width: 576px) {

}
.rbt-input-main {
  border-radius: 50rem !important;
}

.fileUploader {
  border: 0px solid #e1e1e1;
  width: 120px;
  height: 120px;
}

.fileContainer {
  background: #cfcfcf;
  box-shadow: none;
  position: relative;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: all 0.3s ease-in;
  height: 120px;
  width: 120px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.fileContainer p:empty {
  font-size: 12px;
  margin: 0;
}

.fileContainer .uploadPicturesWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  top: 0px;
  height: auto;
}
.uploadPicturesWrapper > div:empty {
  height: 1px;
}

.fileContainer .uploadPictureContainer {
  width: 120px;
  height: 120px;
  margin: 0%;
  padding: 0px;
  background: #edf2f6;
  display: flex;
  align-items: center;
  justify-content: center;
  /*height: inherit;
  */
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
  /*border: 1px solid #d0dbe4;
  */
  position: relative;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.fileContainer .deleteImage {
  position: absolute;
  right: 0px;
  bottom: 0px;
  top: auto;
  text-align: center;
  cursor: pointer;
  font-size: 26px;
  font-weight: bold;
  line-height: 30px;
  width: 30px;
  height: 30px;
  background-image: url(https://cdn.onlinewebfonts.com/svg/img_96165.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 12px 18px;
  background-color: #fff;
  color: black;
  overflow: hidden;
  text-indent: -156px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

/* .fileContainer .deleteImage:after {
  content: "Delete image";
  position: absolute;
  right: -45px;
  font-size: 16px;
} */

.fileContainer .uploadPictureContainer img.uploadPicture {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.fileContainer .errorsContainer {
  max-width: 300px;
  font-size: 12px;
  position: absolute;
  z-index: 10;
  top: 35%;
  background: rgba(255, 0, 0, 0.7);
  padding: 10px;
  text-align: center;
  color: white;
  margin: 0 auto;
  width: 79%;
  border-radius: 6px;
}

.fileContainer .errorsContainer:empty {
  opacity: 0;
}

.fileContainer .chooseFileButton {
  padding: 0;
  /*background-image: url(./icon_upload.svg);
  */
  background-color: #c1c1c1;
  background-repeat: no-repeat;
  background-position: 50% 23%;
  background-size: 25%;
  color: white;
  font-weight: 300;
  font-size: 14px;
  margin: 0;
  transition: all 0.2s ease-in;
  cursor: pointer;
  outline: none;
  border: none;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.fileContainer .chooseFileButton:hover {
  /* background-image: url(./icon_upload.svg); */
  background-color: #c1c1c1;
  background-repeat: no-repeat;
  background-position: 50% 23%;
  background-size: 25%;
}

.profileimg {
  transition: all 0.3s ease-in;
  height: 120px;
  width: 100%;
  border-radius: 50%;
  box-shadow: 0 0 8px 2px rgb(0 0 0 / 10%);
  border: 1px solid #d0dbe4;
}
@media (max-width: 576px) {
  .fileContainer,
  .fileContainer .uploadPictureContainer {
    width: 80px;
    height: 80px;
  }
}

.camericon {
  position: absolute;
  bottom: -11px;
  text-align: center;
  background: white;
  color: #000;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  left: 33%;
  border: 1px solid #ccc;
  line-height: 24px;
  z-index: 1;
}

.deleteImage {
  display: none;
}
.batch_follow {
  background: #fff;
  color: #000;
  font-size: 16px;
  padding: 2px 12px;
  margin: 6px 4px;
  border: 1px solid #ccc;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.eyeIcon {
  position: absolute;
  right: 5px;
  top: 48%;
  cursor: pointer;
}

.page_404 {
  padding: 40px 0;
  background: #fff;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
}

.four_zero_four_bg h1 {
  font-size: 80px;
  font-weight: bold;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
}
.contant_box_404 {
  margin-top: -50px;
}

#bookdivider {
  position: absolute;
  width: 1px;
  border-right: 1px #000 solid;
  height: 80%;
  z-index: 1;
  left: 50%;
  margin-left: -1px;
  top: 10%;
  opacity: .15;
  box-shadow: -2px 0 15px rgb(0 0 0);
  z-index: 888;
}

@media (max-width: 975px) {
  #bookdivider {
    display: none;
  }
}

@media (max-width: 975px) {
.searchfilter{
  position: absolute;
  right: 10px;
  top: 40%;
  width: 40px;
  transform: translateY(-50%);
}
.searchfilter i{
  font-size: 20px;
  cursor: pointer;
}
}

.follow-badge{
  position: absolute;
  top: -4px;
  font-size: 0.52rem;
  border-radius: 20px;
  background-color: #fff;
  padding: 1px 6px;
  font-weight: bold;
  box-shadow: 0 0 8px 2px rgb(0 0 0 / 10%);
}

.nodatacard {
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  border: 1px solid #f0f0f0;
  background: #f9f9f9;
  padding: 20px 10px;
  text-align: center;
  min-height: 280px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.nodatacard h2 {
  margin-top: 20px;
  font-size: 24px;
  color: #000;
  text-transform:none;
}
.nodatacard h2 span {
  display: block;
  font-size: 15px;
  margin-top: 10px;
  color: #999;
}
.nodataimg{
  width: 70%;
 max-width: 300px;
}
.noContent{
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  border: 1px solid #f0f0f0;
  background: #fff;
  padding: 20px 10px;
  text-align: center;
  min-height: 240px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.noContent h2 {
  margin-top: 20px;
  font-size: 24px;
  color: #000;
  text-transform:none;
}
.noContent h2 span {
  display: block;
  font-size: 15px;
  margin-top: 10px;
  color: #999;
  font-weight: bold;
}
.noContent h2 a{
  color: #ed1c24;
}
.noContent .nodataimg{
  width: auto;
  height:160px;
}

@media (max-width: 576px) {
  .nodatacard {
    width: 90%;
    margin: auto;
    min-height: 180px;
    padding: 15px;
  }
  .nodataimg{
    width: 40%;
  }
  .nodatacard h2{
    font-size: 15px;
  }
  .nodatacard h2 span {
    font-size: 11px;
  }
  .noContent .nodataimg {
    height: 110px;
  }
  .noContent h2 {
    margin-top: 10px;
    font-size: 15px;
  }
  .noContent h2 span {
     font-size: 11px;
  }
}

.story-image-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 30%; /* 21:9 Aspect Ratio */
  margin-bottom: 40px;
}

.story-image {
  width: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.previewModal{
  width: 1200px;
  max-width: 90%;
}
.previewVideoModal{
  max-width: 50%;
}
.createGroupMOdal{
  width: 60%;
  max-width: 1000px;
}
.followModal{
  width:70%;
  max-width: 800px;
}

@media (min-width: 372px) and (max-width: 1190px) {
  .previewVideoModal{
    max-width: 80%;
  } 

  .createGroupMOdal{
    width: 90%;
    max-width: 600px;
    margin: auto;
  }
}
.sp_button{
  border: 1px solid #333;
  border-radius: 20px;
  padding: 5px 20px;
  font-weight: bold;
  margin-top: 20px;
  cursor: pointer;
  color: #000;
}

@media (min-width: 372px) and (max-width: 900px) {
  
}

.image-item{
  width: 80px;
  height: 80px;
  border: 3px solid #ccc;
  overflow: hidden;
  border-radius: 50%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.image-item img{

  height: auto !important;

}
.checkIn-box{
  margin-top: 40px;
  justify-content: space-between;
  background: #f7f7f7;
  border-radius: 8px;
  padding: 10px 0px;
  border: 1px solid #f3f3f3;
}
/* .checkIn-box > div{
  width: 100px;
  min-height: 120px;
  margin: 10px 20px;
  padding: 10px;
  border-radius: 15px;
  position: relative;
  display: flex;
  overflow: hidden;

  color: #fff;
  justify-content: center;
} */
.checkIn-box > div > span{
 align-self:flex-end;
 font-size: 38px;

}
/* .checkIn-box > div > div{
  width: 80px;
  height: 80px;
  border-radius: 50%;
  flex-direction: column;
  position: absolute;
  background: #fff;
  top: -25%;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 50px;
  font-size: 12px;
  text-align: center;
  color: #000;
} */
/* .checkIn-box > div:nth-child(1) {
  background-color: #b60a68;
}
.checkIn-box > div:nth-child(2) {
  background-color: #c4b107;
}
.checkIn-box > div:nth-child(3) {
  background-color: #2475b8;
}
.checkIn-box > div:nth-child(4) {
  background-color: #b60a68;
}
.checkIn-box > div:nth-child(5) {
  background-color: #c4b107;
}
.checkIn-box > div:nth-child(6) {
  background-color: #2475b8;
}
.checkIn-box > div:nth-child(7) {
  background-color: #2475b8;
} */
.coinblock{
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.coinblock .coin{
  width: 80px;
  height: 80px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

}

.coinblock .coin h6{
  font-size: 35px;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(34, 34, 34, 0.1);
  background: -webkit-linear-gradient(rgb(255, 254, 190), rgb(255, 240, 23));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0px;
  padding: 0px;
}
.coinblock .dayCount{
  font-size: 12px;
  margin-top: 4px;
  font-weight: bold;
}
@media (max-width: 576px) {
  .checkIn-box{
    margin-top: 20px;
  }
  .coinblock{
    height: 70px;
  }
  .coinblock .coin {
    width: 40px;
    height: 40px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .coinblock .coin h6 {
    font-size: 18px;
  }
}
@media (max-width: 349px) {
  .coinblock .coin {
    width: 36px;
    height: 36px;
  }
  .coinblock .coin h6 {
    font-size: 18px;
  }
  .coinblock .dayCount{
    font-size: 10px;
  }
}
.follower-box{
  font-size: 70px;
  font-weight: bold;
  color: #ed1c24;
  line-height: 70%;
}
.follower-box span{
  font-size: 18px;
  display: block;
}
.follower-box a{
  cursor: pointer;
}
.following-box{
  font-size: 70px;
  font-weight: bold;
  color: #374ea2;
  line-height: 70%;
}
.following-box span{
  font-size: 18px;
  display: block;
}
.following-box a{
  cursor: pointer;
}
.txt-red{
  color: #ed1c24;
  position: relative;
}
.txt-orange{
  color: orange;
  position: relative;
}
.tipMsg{
  position: absolute;
  background: #fff;
  color: #000;
  font-size: 7px;
  border-radius: 4px;
  padding: 2px 5px;
  border: 1px solid #ccc;
  z-index: 44;
  width: auto;
  top: 14px;
  left: 0px;
}
.toast-container{
  z-index: 2222;
}
.datagrid .batch{
  position: absolute;
  background: #fff;
  color: #ed1c24;
  font-size: 7px;
  border-radius: 8px;
  padding: 0px 5px;
  border: 1px solid #ed1c24;
  z-index: 44;
  width: auto;
  top: -2px;
}
.redeemBox .redeemTop{
  padding: 20px 35px;
}
.redeemBox .storyPointBox{
  border-radius: 50%;
  height: 100px;
  width: 100px;
  font-size: 38px;
  font-weight: bolder;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 80%;
  background: #fff;
  justify-content: center;
  color: #ed1c24;
  position: relative;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}
.redeemBox .storyPointBox span{
  font-size: 14px;
  font-weight: normal;
  display: block;
  line-height: normal;
}
.redeemBox .totalPointBox{
border-radius: 8px;
  font-size: 50px;
  font-weight: bold;
  margin: 20px auto;
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 90%;
}
.redeemBox .totalPointBox span{
  font-size: 14px;
  font-weight: normal;
  display: block;
  line-height: normal;
}
.redeemBox .buttonBox{
  display: flex;
  justify-content: space-between;
}
.redeemBox h3{
  font-weight: bold;
  font-size: 28px;
  color: #fff;
  width: 96%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 4px;
  text-shadow: 1px 1px 2px rgba(50, 50, 50, 1);
}
.redeemBox p{
  font-size: 12px;
  color: #fff;
  margin: 0px;
  text-shadow: 1px 1px 2px rgba(50, 50, 50, 1);
}
.redeemBottom{
  background: rgb(252, 253, 247);
  padding: 35px;
}
.redeemModal{
  overflow: hidden;
}
.redeemModal .modal-content{
  padding: 0px;
  overflow: hidden;
  border:0px solid;
}
.redeemModal .modal-content .modal-body{
  padding: 0px;
}

.redeemBox .storyPointBox .note{
  bottom: -5px;
  left:  center;
  background: #000;
  color: #fff;
  border-radius:14px;
  padding: 1px 10px;
  font-size: 8px;
  line-height: 0.7rem;
}

.goldCoin{
  background: url(../images/gold_coin.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}

.joinbox{
  width: 60%;
  max-width: 600px;
  margin: auto;
}
.joinbox .imgGroup{
  width: 60%;
}
.notifyBar{
  display: flex;
  justify-content: space-between;
  padding: 3px 14px;
  margin-bottom: 10px;
  font-size: 11px;
  font-weight: bold;
}
.notifyBar p{
  line-height: 1rem;
  padding: 0px;
  margin: 0px;
}
.notifyBar a{
  line-height: 1rem;
  border-bottom: 1px solid #333;
  padding: 0px;
  margin: 0px;
  cursor: pointer;
}
.notifyBar a:hover{
  color: #ed1c24 !important;
  border-bottom: 1px solid #fff;
}

.storyWriteUpContainer figure{
  overflow: hidden;
}
.storyWriteUpContainer figure img{
  width: 100%;
}

.CropperModal{
  max-width: 800px;
}
.cropperContainer{
  width: 100%;
  height: 400px;
  position: relative;
  background: #333;
}
.cropperControls{
  display: flex;
  padding: 16px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.cropperControls .sliderBox{
  display: flex;
  align-items: center;
  
}
.cropperControls .sliderBox > span{
  padding-right: 5px;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
.reportPop{
  position: absolute;
  border-radius: 6px;
  border:1px solid #ccc;
  padding: 20px;
  background-color: #fff;
  z-index: 99;
  width: 300px;
  right: 0;
  bottom: 40px;
  display: none;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.reportPop h6{
  color: red;
  font-size: 15px;
  font-weight: bold;
}
.reportPop h6 span{
  color: #999;
  font-size: 11px;
  font-weight: bold;
  display: block;
}


@media (min-width: 768px) { 
  .bottomPosition{bottom:0 !important};

 }

 @media (max-width: 576px) { 
.searchbar label{
  font-size: 14px;
}
}


.redeemSucessMsg{
  text-align: center;
  font-size: 1.9rem;
  font-weight: bold;
  color: rgb(81, 155, 39);
  margin-bottom: 50px;
}
.redeemSucessMsg span{
  display: block;
  text-align: center;
  font-size: 1rem;
  color: #999;
}

.modal-backdrop.show{
  opacity: 0.8;
}


.pyro > .before, .pyro > .after {
  position: absolute;
  z-index: 100086;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: -120px -218.66667px blue, 248px -16.66667px #00ff84, 190px 16.33333px #002bff, -113px -308.66667px #ff009d, -109px -287.66667px #ffb300, -50px -313.66667px #ff006e, 226px -31.66667px #ff4000, 180px -351.66667px #ff00d0, -12px -338.66667px #00f6ff, 220px -388.66667px #99ff00, -69px -27.66667px #ff0400, -111px -339.66667px #6200ff, 155px -237.66667px #00ddff, -152px -380.66667px #00ffd0, -50px -37.66667px #00ffdd, -95px -175.66667px #a6ff00, -88px 10.33333px #0d00ff, 112px -309.66667px #005eff, 69px -415.66667px #ff00a6, 168px -100.66667px #ff004c, -244px 24.33333px #ff6600, 97px -325.66667px #ff0066, -211px -182.66667px #00ffa2, 236px -126.66667px #b700ff, 140px -196.66667px #9000ff, 125px -175.66667px #00bbff, 118px -381.66667px #ff002f, 144px -111.66667px #ffae00, 36px -78.66667px #f600ff, -63px -196.66667px #c800ff, -218px -227.66667px #d4ff00, -134px -377.66667px #ea00ff, -36px -412.66667px #ff00d4, 209px -106.66667px #00fff2, 91px -278.66667px #000dff, -22px -191.66667px #9dff00, 139px -392.66667px #a6ff00, 56px -2.66667px #0099ff, -156px -276.66667px #ea00ff, -163px -233.66667px #00fffb, -238px -346.66667px #00ff73, 62px -363.66667px #0088ff, 244px -170.66667px #0062ff, 224px -142.66667px #b300ff, 141px -208.66667px #9000ff, 211px -285.66667px #ff6600, 181px -128.66667px #1e00ff, 90px -123.66667px #c800ff, 189px 70.33333px #00ffc8, -18px -383.66667px #00ff33, 100px -6.66667px #ff008c;
  -moz-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -webkit-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -o-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -ms-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards; }

.pyro > .after {
  -moz-animation-delay: 1.25s, 1.25s, 1.25s;
  -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
  -o-animation-delay: 1.25s, 1.25s, 1.25s;
  -ms-animation-delay: 1.25s, 1.25s, 1.25s;
  animation-delay: 1.25s, 1.25s, 1.25s;
  -moz-animation-duration: 1.25s, 1.25s, 6.25s;
  -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
  -o-animation-duration: 1.25s, 1.25s, 6.25s;
  -ms-animation-duration: 1.25s, 1.25s, 6.25s;
  animation-duration: 1.25s, 1.25s, 6.25s; }

@-webkit-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white; } }
@-moz-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white; } }
@-o-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white; } }
@-ms-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white; } }
@keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white; } }
@-webkit-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0; } }
@-moz-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0; } }
@-o-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0; } }
@-ms-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0; } }
@keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0; } }
@-webkit-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%; }

  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%; }

  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%; }

  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%; }

  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%; } }
@-moz-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%; }

  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%; }

  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%; }

  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%; }

  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%; } }
@-o-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%; }

  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%; }

  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%; }

  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%; }

  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%; } }
@-ms-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%; }

  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%; }

  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%; }

  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%; }

  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%; } }
@keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%; }

  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%; }

  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%; }

  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%; }

  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%; } }



    
/* Icon 1 */

.nav-icon1 {
  width: 24px;
  height: 32px;
  position: relative;
  margin: 0px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

.nav-icon1 span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: #000;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

.nav-icon1 span:nth-child(1) {
  top: 0px;
}

.nav-icon1 span:nth-child(2) {
  top: 1px;
}

.nav-icon1 span:nth-child(3) {
  top: 3px;
}

.nav-icon1.open span:nth-child(1) {
  top: 18px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

.nav-icon1.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.nav-icon1.open span:nth-child(3) {
  top: 18px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.termsContentBox{
  overflow-y:scroll;
  height: 55vh;
}

.react-datepicker-popper{
  z-index: 999;
}

h3.purpleColor{
  position: relative;
}

h3.purpleColor i{
  font-size: 20px;
position: absolute;
top: -8px;
}
.datagrid table td i.bi-patch-check-fill{
  font-size: 12px;
position: absolute;
top: -2px;
color: #b60a68;
}