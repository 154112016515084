.customforminput  {
    border:0 0 0  !important;
    border-right:0 !important;
    border-left:0 !important;
    border-top:0 !important;
    border-bottom:1px solid#707070 !important;
    border-radius:0 !important;
    margin-bottom:20px
    }

    .customforminput:focus {
      box-shadow: none;  
    }

    