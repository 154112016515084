.react-epubjs {
    position: relative;
    width: 100%;
    height: 100%;
    border:1px solid #ccc;
    border-radius: 8px;
    padding-top: 40px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 50px inset;
    transition: all 0.3s ease 0s;
}
.react-epubjs, .react-epubjs * {
    box-sizing: border-box;
}
.react-epubjs .reader {
    width: 100%;
    height: 100%;
    z-index: 1;
}
.react-epubjs .reader.pb-25 {
    padding-bottom: 25px;
}