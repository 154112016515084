.headMenu a{
    color: #393939;
}
.headMenu a:hover{
    color: #ed1c24;
}

.headMenu .nav-link.active{
    color: #ed1c24;
}
