.react-epubjs .more {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 2;
}
.react-epubjs .more .setting {
    width: 100%;
    height: 60%;
    background: #8a8a8a c7;
}
.react-epubjs .more .cancel {
    width: 100%;
    height: 40%;
}
.react-epubjs .more .info {
    height: 3rem;
    background: #f2f2f2;
    line-height: 3rem;
}
.react-epubjs .more .info .title {
    color: #000;
    text-align: center;
    font-weight: bold;
}
.react-epubjs .more .chapters {
    background-color: #fff;
    z-index: 3;
    padding: 1rem;
    height: calc(100% - 6rem);
    overflow: auto;
}
.react-epubjs .more .chapters .chapter {
    padding: 0.4rem;
    text-decoration: underline;
    display: table;
    margin-bottom: 0px;
    position: relative;
    cursor: pointer;

}
.react-epubjs .more .chapters .chapter ol {
    margin-bottom: 5px;
}
.react-epubjs .more .chapters .chapter ol li{
    font-size: 12px;

}
.react-epubjs .more .chapters .chapter span{
    position: absolute;
    top: -2px;
    font-size: 12px;
    font-weight: bold;
}
.react-epubjs .more .chapters .chapter span i{
    font-size: 10px;
    font-weight: bold;
    color: #ee0540;
}