.btnClose {
    border: 1px solid #000;
    border-radius: 50%;
    padding: 0 !important;
    position:relative;
    right: 10px ;
    width: 1.5em;
    height: 1.5em;
    top:10px;
    color:#434343;
}


.purpleModal .modal-content {
    background:#B62079;
}

